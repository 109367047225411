import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Piloni from "../components/piloni"
import NumbersSortiment from "../components/numbers-sortiment"

import HeroProduseVerzi from "../images/hero-sortiment-sustenabil.jpg"
import HeroProduseVerziM from "../images/hero-sortiment-sustenabil-m.jpg"

import ProduseLocale from "../images/produse-locale.jpg"
import Etica from "../images/etica-procese.jpg"
import ManagementulAmbalajelor from "../images/managementul-ambalajelor.jpg"
import PromovareaMutritiei from "../images/promovarea-nutritiei.jpg"

import DownloadIcon from "../images/download.svg"

import ArrowLeft from "../images/arrow-left-green.svg"
import ArrowRight from "../images/arrow-right-green.svg"

class ProduseVerziPage extends React.Component {
  componentDidMount() {
    $(".video-carousel").slick({
      slidesToShow: 1,
      prevArrow: ".pillars-arrow-left-2",
      nextArrow: ".pillars-arrow-right-2",
      infinite: true,
    })
  }

  render() {
    return (
      <Layout pageInfo={{ pageName: "produse-verzi" }}>
        <SEO title="Sortiment sustenabil" />
        <section className="bg-light">
          <div className="hero text-center mx-auto">
            <img
              className="d-none d-lg-block w-100"
              src={HeroProduseVerzi}
              alt=""
            />
            <img className="d-lg-none w-100" src={HeroProduseVerziM} alt="" />
          </div>
        </section>
        <div className="container">
          <div className="row mt-5">
            <div className="col-10 my-5 mx-auto">
              <h1 className="text-success text-center akko-bold py-3">Suntem aproape de consumatorii noștri  cu sortimente sustenabile!</h1>
              <p className="akko-regular text-center">
                Dezvoltarea și promovarea consumului de produse sustenabile rămân două dintre  obiectivele principale ale PENNY.
                În calitate de retailer multinațional, atenția noastră se îndreaptă în principal pe probleme legate de sortiment, pe care lucrăm constant să le soluționăm eficient. Printre acestea se află subiecte precum standardele sociale din lanțul de  aprovizionare, de la recoltare la producție și  până la logistică, bunăstarea animalelor  precum și eficiența resurselor în ceea ce privește ambalarea produselor noastre.
              </p>
            </div>
            <div className="col-12 pb-5 text-center">
              <a
                href="/raport-de-sustenabilitate-2023"
                className="btn btn-primary akko-bold mx-auto"
              >
                Descarca raport
                <img
                  className="icon-badge-white ml-2"
                  src={DownloadIcon}
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>

        <NumbersSortiment />

        <div className="section-info section-right row no-gutters">
          <div className="col-12 col-lg-6">
            <div
              className="section-bg h-100"
              style={{
                backgroundImage: `url(${ProduseLocale})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            />
          </div>
          <div className="col-12 col-lg-6">
            <div className="section-text py-6 py-lg-8 pr-20 pr-lg-6 pr-xl-8 pr-xxl-12 pl-20 pl-xl-8">
              <h1 className="text-success akko-bold mb-3">
                REGIONALITATE ȘI PRODUSE LOCALE
              </h1>
              <p className="mb-0">
                La PENNY, ne axăm pe achiziționarea de sortimente de la producători regionali și colaborări pe termen lung cu acestia. Strategia noastră este de a le oferi clienților produse procesate în România. Pentru  a menține un impact pozitiv, urmărim să fim cât mai aproape de comunitățile în care activăm prin oferirea de produse regionale și prin mărci proprii realizate după  rețete tipic românești.
                <br/><br/>
                Pentru a veni în întâmpinarea așteptărilor clienților  noștri și în același timp pentru a susține economia  românească, selectăm partenerii noștri, în funcție de  categoriile de produse furnizate. Parteneriatele curente  cu furnizori locali ne asigură un flux constant de produse  proaspete, regionale și pe placul clienților noștri precum:  pâinea, fructele și legumele sau vinurile. Întreprindem  eforturi constante pentru a crește procentul de produse  regionale la cel mai bun preț. Află mai multe <a href="https://sustenabilitate.penny.ro/raport-de-sustenabilitate-2022/93/">aici</a>.
                <br/><br/>
                În anul 2020, ne-am asumat un obiectiv ambițios, sub umbrela TripluRO (3RO): ne-am  propus ca până la finalul anului 2023, 60% din sortimentul aflat la vânzare în magazinele  PENNY să aibă ingredientul principal provenit din România, adică să fie cultivat,  crescut, extras sau fabricat în țară și să fie procesat și ambalat în România. Află mai multe <a href="https://www.penny.ro/triplu-ro">aici</a>.
                Pentru că știm cât de mult îi place consumatorului PENNY sa fie mereu surprins cu ceva nou și de calitate, adunăm periodic cele mai noi produse, la prețuri avantajoase, care să reflecte nevoile și dorințele sale. Mărcile private reprezintă aproximativ 30% din cifra noastră de afaceri, iar furnizorii noștri se obligă contractual  să respecte codul nostru de conduită. Află mai multe <a href="https://sustenabilitate.penny.ro/raport-de-sustenabilitate-2022/97/">aici</a>.
              </p>
            </div>
          </div>
        </div>

        <div className="section-info section-left row no-gutters">
          <div className="col-12 col-lg-6 order-2 order-lg-1">
            <div className="section-text py-6 py-lg-8 pl-20 pl-lg-6 pl-xl-8 pl-xxl-12 pr-20 pr-xl-8">
              <h1 className="text-success akko-bold mb-3">
                ETICA ÎN PROCESELE DE APROVIZIONARE
              </h1>
              <p className="m-0">
                Principalul obiectiv pentru noi, este acela de a îndeplini  și depăși așteptările clienților noștri care sunt din ce în  ce mai preocupați de proveniența alimentelor și acordă  o mai mare atenție modului în care sunt produse, din perspectiva impactului asupra mediului, dar și a justiției sociale și a standardelor de siguranță alimentară.  Integrând sustenabilitatea în lanțul de aprovizionare, ne  îmbunătățim performanța, reducem riscurile, construim  și consolidăm încrederea în produsele comercializate de noi. Pe baza aplicării principiilor dezvoltării durabile, controlul aprovizionării și dezvoltarea criteriilor ecologice în selectarea furnizorilor externi, am dezvoltat  lanțuri de aprovizionare sustenabile. 
                <br/><br/>
                Toți furnizorii noștri  sunt îndrumați să respecte codul nostru de conduită care  definește standardele minime nenegociabile pe care  furnizorii trebuie să le respecte în tranzacțiile comerciale. Prin intermediul codului de conduită ei sunt obligați să determine, să analizeze și să prioritizeze drepturile  omului și impacturile ecologice al activităților de afaceri  și să ia măsurile adecvate pentru a le remedia sau atenua. 
                Stabilirea și îmbunătățirea continuă a standardelor sociale și de mediu și a practicilor specifice de-a lungul lanțului de aprovizionare este un proces îndelungat, dar deosebit  de important pentru a le oferi clienților noștri produse sustenabile. Află mai multe <a href="https://sustenabilitate.penny.ro/raport-de-sustenabilitate-2022/105/">aici</a>.
              </p>
            </div>
          </div>
          <div className="col-12 col-lg-6 order-1 order-lg-2">
            <div
              className="section-bg h-100"
              style={{
                backgroundImage: `url(${Etica})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            />
          </div>
        </div>

        <div className="section-info section-right row no-gutters">
          <div className="col-12 col-lg-6">
            <div
              className="section-bg h-100"
              style={{
                backgroundImage: `url(${ManagementulAmbalajelor})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            />
          </div>
          <div className="col-12 col-lg-6">
            <div className="section-text py-6 py-lg-8 pr-20 pr-lg-6 pr-xl-8 pr-xxl-12 pl-20 pl-xl-8">
              <h1 className="text-success akko-bold mb-3">
                MANAGEMENTUL AMBALAJELOR
              </h1>
              <p className="mb-0">
                Ambalajele au un rol important în menținerea calității și prospețimii produselor, însă fără o gestionare corespunzătoare, acestea pot aduce numeroase efecte  negative pentru mediul înconjurător, atât în etapa de  fabricare a ambalajelor, cât și la eliminarea acestora.  Ne confruntăm cu aceste provocări și în lanțurile de  aprovizionare ale PENNY Romania și considerăm că  suntem responsabili să integrăm utilizarea ambalajelor  sustenabile,  să contribuim la implementarea elementelor  de economie circulară și să încurajăm consumatorii privind  colectarea selectivă și prevenirea risipei alimentare. Află mai multe <a href="https://sustenabilitate.penny.ro/raport-de-sustenabilitate-2022/119/">aici</a>.
                <br/><br/>
                La PENNY facem tot ce putem pentru o lume mai bună și un viitor mai curat. Clienții care  își fac cumpărăturile din magazinele PENNY să contribuie la reducerea deșeurilor cu  ajutorul campaniilor noastre.
              </p>
            </div>
          </div>
        </div>

        <div className="section-info section-left row no-gutters">
          <div className="col-12 col-lg-6 order-2 order-lg-1">
            <div className="section-text py-6 py-lg-8 pl-20 pl-lg-6 pl-xl-8 pl-xxl-12 pr-20 pr-xl-8">
              <h1 className="text-success akko-bold mb-3">
                PROMOVAREA NUTRIȚIEI SĂNĂTOASE
              </h1>
              <p className="m-0">
                Obiceiurile alimentare un impact considerabil din  perspectiva sănătății și au bunăstării comunităților. Ne dorim ca portofoliul nostru de produse marcă proprie să respecte într-o proporție din ce în ce mai mare principiile nutriției sănătoase și echilibrate. De aceea, stabilim standarde înalte pentru produsele comercializate în magazinele noastre, astfel contribuind la promovarea  unui stil de viață sănătos atât pentru angajații cât și  pentru clienții noștri.
                Prin gama My BIO oferim produse necesare pentru o nutriție echilibrată și sănătoasă,  acestea fiind produse naturale controlate riguros și fără modificări genetice sau urme  de pesticide. Produsele proaspete MY BIO sunt certificate organic în UE, perfecte pentru  pregătirea unor preparate sănătoase. Sortimentul cuprinde băuturi pe bază de cereale,  dulciuri, conserve dar și produse cum ar fi lactate, ouă și miere. Află mai multe <a href="https://sustenabilitate.penny.ro/raport-de-sustenabilitate-2022/126/">aici</a>.
              </p>
            </div>
          </div>
          <div className="col-12 col-lg-6 order-1 order-lg-2">
            <div
              className="section-bg h-100"
              style={{
                backgroundImage: `url(${PromovareaMutritiei})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            />
          </div>
        </div>{" "}
        <div className="bg-light pt-5 mb-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-9 my-4 position-relative">
                <img
                  className="numbers-arrow pillars-arrow-left-2"
                  src={ArrowLeft}
                  alt=""
                />
                <div className="video-carousel px-4">
                  <div className="video-item">
                    <div className="video">
                      <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/yTPAhTACwe4"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      />
                    </div>
                  </div>
                  <div className="video-item">
                    <div className="video">
                      <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/oOKhPzVeC3k"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      />
                    </div>
                  </div>
                  <div className="video-item">
                    <div className="video">
                      <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/Ems661yfmGg"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      />
                    </div>
                  </div>
                  <div className="video-item">
                    <div className="video">
                      <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/8gMfmch-W0Q"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      />
                    </div>
                  </div>
                </div>
                <img
                  className="numbers-arrow pillars-arrow-right-2"
                  src={ArrowRight}
                  alt=""
                />
              </div>
            </div>
            <div className="row pb-5">
              <a
                href="/raport-de-sustenabilitate-2023"
                className="btn btn-primary akko-bold mx-auto"
              >
                Descarca raport
                <img
                  className="icon-badge-white ml-2"
                  src={DownloadIcon}
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
        <div className="container">
          <Piloni hide="1" />
        </div>
      </Layout>
    )
  }
}
export default ProduseVerziPage

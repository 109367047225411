import React from "react"

import { CountUp } from "countup.js"

import CarneIcon from "../images/carne.png"
import CartIcon from "../images/cos-cumparaturi.png"
import DocumentIcon from "../images/document.png"
import GrupIcon from "../images/grup.png"
import LegumeIcon from "../images/legume.png"
import SmileyIcon from "../images/smiley-face.png"
import Angajati from "../images/angajati-penny.png"
import Cifra from "../images/cifra-afaceri.png"
import LedIcon from "../images/led.png"
import Magazine from "../images/magazine.png"
import Voluntari from "../images/voluntari.png"
import Pig from "../images/pig.png"

import ArrowLeft from "../images/arrow-left.svg"
import ArrowRight from "../images/arrow-right.svg"

class NumbersSortiment extends React.Component {
  componentDidMount() {
    const self = this

    $("#numbers-carousel")
      .slick({
        slidesToShow: 3,
        prevArrow: ".numbers-arrow-left",
        nextArrow: ".numbers-arrow-right",
        infinite: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      })
      .on("afterChange", function(event, slick, currentSlide, nextSlide) {
        self.runNumbers()
      })

    this.runNumbers()
  }

  // eslint-disable-next-line class-methods-use-this
  runNumbers() {
    $(".counter").each(function() {
      const item = $(this)
      const end = item.attr("data-end")
      const start = item.attr("data-start")

      const count = new CountUp(this.id, end, { startVal: start, useGrouping: true, decimal: ',', separator: '.' })
      count.start()
    })
  }

  render() {
    return (
      <div className="penny-numbers-container mt-5">
        <h1 className="text-success text-center akko-bold py-3">
          PENNY în cifre
        </h1>
        <div className="bg-success">
          <div className="container position-relative py-5">
            <img
              className="numbers-arrow numbers-arrow-left"
              src={ArrowLeft}
              alt=""
            />
            <div id="numbers-carousel">
              <div>
                <div className="penny-number-body text-center">
                  <div className="penny-number-icon">
                    <img className="mx-auto" src={Cifra} alt="" />
                  </div>
                  <h2 className="akko-bold text-white">
                    <span>
                      86
                    </span>
                    %
                  </h2>
                  <p className="text-white">din totalul cifrei de afaceri de la furnizorii local</p>
                </div>
              </div>

              <div>
                <div className="penny-number-body text-center">
                  <div className="penny-number-icon">
                    <img className="mx-auto" src={Pig} alt="" />
                  </div>
                  <h2 className="akko-bold text-white">
                    <span
                      id="counter-produser"
                      className="counter"
                      data-end="39"
                      data-start="0"
                    >
                      39
                    </span>
                    %
                  </h2>
                  <p className="text-white">
                    proporția produselor produse 3RO
                  </p>
                </div>
              </div>

              <div>
                <div className="penny-number-body text-center">
                  <div className="penny-number-icon">
                    <img className="mx-auto" src={Cifra} alt="" />
                  </div>
                  <h2 className="akko-bold text-white">
                    <span>
                      58
                    </span>
                    %
                  </h2>
                  <p className="text-white">
                  din furnizori au o relație contractuală cu PENNY de peste 5 ani
                  </p>
                </div>
              </div>
              <div>
                <div className="penny-number-body text-center">
                  <div className="penny-number-icon">
                    <img className="mx-auto" src={LegumeIcon} alt="" />
                  </div>
                  <h2 className="akko-bold text-white">
                    <span>
                      69
                    </span>
                    %
                  </h2>
                  <p className="text-white">
                    proporția produselor alimentare de origine regională (din România)
                  </p>
                </div>
              </div>

              <div>
                <div className="penny-number-body text-center">
                  <div className="penny-number-icon">
                    <img className="mx-auto" src={CarneIcon} alt="" />
                  </div>
                  <h2 className="akko-bold text-white">
                    <span
                      id="counter-cat"
                      className="counter"
                      data-end="198"
                      data-start="0"
                    >
                      198
                    </span>
                  </h2>
                  <p className="text-white">
                    de măcelării partenere ale fermierilor și producătorilor locali
                  </p>
                </div>
              </div>

              <div>
                <div className="penny-number-body text-center">
                  <div className="penny-number-icon">
                    <img className="mx-auto" src={CartIcon} alt="" />
                  </div>
                  <h2 className="akko-bold text-white">
                    <span
                      id="counter-instruire"
                      className="counter"
                      data-end="84"
                      data-start="0"
                    >
                      84
                    </span>{" "}
                  </h2>
                  <p className="text-white">
                    de articole ecologice și pentru dietă specială comercializate
                  </p>
                </div>
              </div>

              <div>
                <div className="penny-number-body text-center">
                  <div className="penny-number-icon">
                    <img className="mx-auto" src={Angajati} alt="" />
                  </div>
                  <h2 className="akko-bold text-white">
                    <span
                      id="counter-angajati"
                      className="counter"
                      data-end="246"
                      data-start="0"
                    >
                      246
                    </span>{" "} 
                  </h2>
                  <p className="text-white">articole nou dezvoltate cu ambalaje secundare certificate FSC</p>
                </div>
              </div>
            
            </div>

            <img
              className="numbers-arrow numbers-arrow-right"
              src={ArrowRight}
              alt=""
            />
          </div>
        </div>
      </div>
    )
  }
}

export default NumbersSortiment
